import React, { useEffect } from 'react'
import Navbar from '../arComponents/NavBar'
import Footer from '../arComponents/Footer'
import imgtest from '../Assets/images/contact.03d0181f.gif'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../Components/Loader'
import { aboutPageAction } from '../redux/aboutus'

const ArAboutUs = ({ history }) => {

    const dispatch = useDispatch()

    const aboutData = useSelector(state => state.aboutPage)
    const { loading, data, error } = aboutData

    useEffect(() => {
        dispatch(aboutPageAction())
    }, [dispatch])

    return (
        <>
            {loading && <Loader />}

            {data && (
                <>
                    <Navbar history={history} />
                    <div className='provada-store-about-AR'>
                        <div className="registration-steps-band artboard">
                            <h2 style={{ textAlign: "center" }}>عن مؤسسة بروفادا</h2>
                        </div>

                        <section className='about-first-section'>
                            <div className="container">
                                <h3>{data.left_paragragh_ar}</h3>
                                <div>
                                    <p style={{ textAlign: "right" }} dir="rtl">{data.right_paragragh_ar}</p>
                                    <img src={data.image} alt="" />
                                </div>
                            </div>
                        </section>

                        <section className='about-video'>
                            <div className="container">
                                <div>
                                    <img src={imgtest} alt="" />
                                </div>
                            </div>
                        </section>

                        <section className='vision-mission-history-about'>
                            <div className="container">
                                <div>
                                    <i class="fa-solid fa-eye"></i>
                                    <h3>الرؤية</h3>
                                    <p style={{ textAlign: "right" }} dir="rtl">{data.vision_ar}</p>
                                </div>
                                <div>
                                    <i class="fa-solid fa-bullseye"></i>
                                    <h3>المهمة</h3>
                                    <p>{data.mission_ar}</p>
                                </div>
                                <div>
                                    <i class="fa-solid fa-clock-rotate-left"></i>
                                    <h3>التاريخ</h3>
                                    <p style={{ textAlign: "right" }} dir="rtl">{data.history_ar}</p>
                                </div>
                            </div>
                        </section>
                    </div>
                    <Footer />
                </>
            )}
        </>
    )
}

export default ArAboutUs