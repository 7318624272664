import React from 'react'
import FadeLoader from "react-spinners/FadeLoader";

const Loader = () => {
    return (
        <div style={{ height: "80vh" }} className="container d-flex justify-content-center align-items-center">
            <FadeLoader color='#f3911e' />
        </div>
    )
}

export default Loader