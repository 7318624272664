import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Button, Modal } from "react-bootstrap";

const MyVerticallyCenteredModal = ({
  onHide,
  show,
  description,
  image,
  title,
  price,
}) => {
  return (
    <>
      <Modal
        onHide={onHide}
        show={show}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="product_modal_AR"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Product Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal_Book_container">
            <div>
              <img src={image} alt="" />
            </div>
            <div>
              <h3>{title}</h3>
              {/* <h5>${price}</h5> */}
              <p>{description}</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const TopProducts = ({ product }) => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <img
        src={product.product.image}
        alt={product.product.title_ar}
        style={{
          maxHeight: "400px",
          minHeight: "399px",
          maxWidth: "565px",
          cursor: "pointer",
        }}
        onClick={() => setModalShow(true)}
      />
      <div>
        <h1 onClick={() => setModalShow(true)} style={{ cursor: "pointer" }}>
          {product.product.title_ar}
        </h1>
        <div className="line"></div>
        <p onClick={() => setModalShow(true)} style={{ cursor: "pointer" }}>
          {product.product.description_ar}
        </p>
      </div>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        description={product.product.description_ar}
        image={product.product.image}
        title={product.product.title_ar}
      />
    </>
  );
};

export default TopProducts;
