import axios from 'axios'
const PRODUCTS_LIST_REQUEST = 'PRODUCTS_LIST_REQUEST'
const PRODUCTS_LIST_SUCCESS = 'PRODUCTS_LIST_SUCCESS'
const PRODUCTS_LIST_FAIL = 'PRODUCTS_LIST_FAIL'

const TOP_PRODUCTS_LIST_REQUEST = 'TOP_PRODUCTS_LIST_REQUEST'
const TOP_PRODUCTS_LIST_SUCCESS = 'TOP_PRODUCTS_LIST_SUCCESS'
const TOP_PRODUCTS_LIST_FAIL = 'TOP_PRODUCTS_LIST_FAIL'

export const productsListAction = (page, sortValue, sortDate, keyword, min, max, category) => async (dispatch) => {

    try {
        dispatch({
            type: PRODUCTS_LIST_REQUEST
        })
        const { data } = page ? await axios.get(`https://provada-store.herokuapp.com/api/products`, {
            params: {
                page: page.replace("?", "").replace("page", "").replace("=", ""),
                s: sortValue,
                d: sortDate,
                keyword: keyword,
                min: min,
                max: max,
                category: category
            }
        })
            : await axios.get(`https://provada-store.herokuapp.com/api/products`, {
                params: {
                    s: sortValue,
                    d: sortDate,
                    keyword: keyword,
                    min: min,
                    max: max,
                    category: category
                }
            })

        dispatch({
            type: PRODUCTS_LIST_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: PRODUCTS_LIST_FAIL,
            payload: error
        })
    }
}

export const productsListReducer = (state = { products: [] }, action) => {
    switch (action.type) {
        case PRODUCTS_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case PRODUCTS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                products: action.payload,
                page: action.payload.page,
                pages: action.payload.pages,
            }
        case PRODUCTS_LIST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export const topProductsListAction = (id) => async (dispatch) => {

    try {
        dispatch({
            type: TOP_PRODUCTS_LIST_REQUEST
        })

        const { data } = await axios.get(`https://provada-store.herokuapp.com/api/products/${id}/`)

        dispatch({
            type: TOP_PRODUCTS_LIST_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: TOP_PRODUCTS_LIST_FAIL,
            payload: error
        })
    }
}

export const topProductsListReducer = (state = {}, action) => {
    switch (action.type) {
        case TOP_PRODUCTS_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case TOP_PRODUCTS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            }
        case TOP_PRODUCTS_LIST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}