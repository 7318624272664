import Homepage from "./pages/Homepage";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";

import { HashRouter as Router, Route } from "react-router-dom";
import Home from "./Components/Home";
import ArHomepage from "./arPages/Homepage";
import ArAboutUs from "./arPages/AboutUs";
import ArContactUs from "./arPages/ContactUs";
function App() {
  return (
    <div className="App">
      <Router>
        <Route exact path="/" component={Home} />
        <Route exact path="/homepage" component={Homepage} />
        <Route path="/about" component={AboutUs} />
        <Route path="/contact" component={ContactUs} />

        {/* AR Routes */}
        <Route path="/AR/homepage" component={ArHomepage} />
        <Route path="/AR/about" component={ArAboutUs} />
        <Route path="/AR/contact" component={ArContactUs} />
      </Router>
    </div>
  );
}

export default App;
