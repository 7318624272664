import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import "../node_modules/swiper/swiper-bundle.css";
import "../node_modules/swiper/swiper.min.css";
import "../node_modules/swiper/swiper-bundle.min.css";
import "./Assets/style/main.sass";

import { Provider } from "react-redux";
import store from "./store";

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
