import axios from 'axios'
const ABOUT_PAGE_REQUEST = 'ABOUT_PAGE_REQUEST'
const ABOUT_PAGE_SUCCESS = 'ABOUT_PAGE_SUCCESS'
const ABOUT_PAGE_FAIL = 'ABOUT_PAGE_FAIL'

export const aboutPageAction = () => async (dispatch) => {

    try {
        dispatch({
            type: ABOUT_PAGE_REQUEST
        })
        const { data } = await axios.get(`https://provada-store.herokuapp.com/api/about/`)

        dispatch({
            type: ABOUT_PAGE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: ABOUT_PAGE_FAIL,
            payload: error
        })
    }
}

export const aboutPageReducer = (state = {}, action) => {
    switch (action.type) {
        case ABOUT_PAGE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ABOUT_PAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            }
        case ABOUT_PAGE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}