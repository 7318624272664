import React, { useEffect, useState } from "react";

import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Button, Modal } from "react-bootstrap";

import SaleProduct from "../arComponents/SaleProduct";
import Faqs from "../arComponents/Faqs";
import { useDispatch, useSelector } from "react-redux";
import { productsListAction } from "../redux/products";
import Footer from "../arComponents/Footer";
import TopProducts from "../arComponents/TopProducts";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Range } from "react-range";
import Pagination from "@mui/material/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import Stack from "@mui/material/Stack";

import BookC from "../arComponents/Book";
import Loader from "../Components/Loader";
import BestSellers from "../arComponents/BestSellers";
import ArNavBar from "../arComponents/NavBar";

const ArHomepage = ({ history }) => {
  const dispatch = useDispatch();

  const [value, setValue] = useState([1, 500]);
  const [minMax, setMinMax] = useState([value[0], value[1]]);
  const [categoryValue, setCategoryValue] = useState("None");
  const [hangleCatColor, setHandleCatColor] = useState(NaN);

  const [values, setValues] = useState([50, 500]);

  const handleCategoryFilter = (value) => {
    if (categoryValue === value) {
      setCategoryValue("None");
      setHandleCatColor(NaN);
    } else {
      setCategoryValue(value);
      setHandleCatColor(value);
    }
  };

  const HandleCatColorFun = (num) => { };

  // const handleBGcolor = (val) => {};

  const searchFilter = (e) => {
    e.preventDefault();
    setTimeout(() => setKeyword(e.target.value), 1000);
  };

  const rangeSelector = (value) => {
    setValues(value);

    // setTimeout(() => setMinMax(value), 1000);
  };

  const productsList = useSelector((state) => state.productsList);
  const {
    loading,
    products: { products, categories, bestSellers, banner, faqs, topProducts },
    error,
    pages,
  } = productsList;

  const [isRow, setIsRow] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortValue, setSortValue] = useState("title_en");
  const [sortDate, setSortDate] = useState("-creation_date");

  const handleColumnBtn = () => {
    document.getElementById("column-book").classList.add("active");
    document.getElementById("row-book").classList.remove("active");
    setIsRow(false);
  };

  const handleRowBtn = () => {
    document.getElementById("column-book").classList.remove("active");
    document.getElementById("row-book").classList.add("active");
    setIsRow(true);
  };

  const handlePageChange = (e, value) => {
    e.preventDefault();
    setPageNumber(value);
    history.push(`?page=${value}`);
  };

  const sortHandler = (e) => {
    e.preventDefault();
    setSortValue(e.target.value);
  };

  const sortDateHandler = (e) => {
    e.preventDefault();
    setSortDate(e.target.value);
  };

  let page = history.location.search;
  const [keyword, setKeyword] = useState("");

  SwiperCore.use([Autoplay]);

  var bgColorActive = {
    backgroundColor: "#d8d8d8",
  };
  var bgColornoAvtive = {
    backgroundColor: "white",
  };

  useEffect(() => {
    if (history.location.search.includes("?category=Stationery")) {
      page = "?page=1";
      dispatch(
        productsListAction(
          page,
          sortValue,
          sortDate,
          keyword,
          minMax[0],
          minMax[1],
          history.location.search
            .replace("?", "")
            .replace("category", "")
            .replace("=", "")
        )
      );
      // history.replace("?", "").replace("category", "").replace("=", "").replace("Stationery", "")
    } else if (history.location.search.includes("?category=Books")) {
      page = "?page=1";
      dispatch(
        productsListAction(
          page,
          sortValue,
          sortDate,
          keyword,
          minMax[0],
          minMax[1],
          history.location.search
            .replace("?", "")
            .replace("category", "")
            .replace("=", "")
        )
      );
    } else if (history.location.search.includes("?q=")) {
      page = "?page=1";
      dispatch(
        productsListAction(
          page,
          sortValue,
          sortDate,
          history.location.search
            .replace("?", "")
            .replace("q", "")
            .replace("=", ""),
          minMax[0],
          minMax[1],
          categoryValue
        )
      );
    } else {
      dispatch(
        productsListAction(
          page,
          sortValue,
          sortDate,
          keyword,
          minMax[0],
          minMax[1],
          categoryValue
        )
      );
    }
  }, [dispatch, page, sortValue, sortDate, keyword, minMax, categoryValue]);

  const MyVerticallyCenteredModal = ({
    onHide,
    show,
    description,
    image,
    title,
    price,
  }) => {
    return (
      <>
        <Modal
          onHide={onHide}
          show={show}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="product_modal_AR"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Product Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal_Book_container">
              <div>
                <img src={image} alt="" />
              </div>
              <div>
                <h3>{title}</h3>
                <h5>${price}</h5>
                <p>{description}</p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onHide}>Close</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      {loading && <Loader />}
      {products && (
        <>
          <ArNavBar history={history} categories={categories} />

          <Swiper className="mySwiper-hero-AR" loop={true}>
            {topProducts.map((product) => (
              <SwiperSlide key={product.id} className="swiper-slide">
                <TopProducts product={product} />
              </SwiperSlide>
            ))}
          </Swiper>

          <div className="books-AR" style={{ marginTop: "5rem" }}>
            <div className="container">
              {/* Aside Filter */}

              <div className="search-filter">
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": { m: 1, width: "25ch" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    onChange={searchFilter}
                    className="input-con"
                    id="standard-basic"
                    label="بحث"
                    variant="standard"
                    style={{ widht: "100%" }}
                  />
                </Box>
                <div
                  style={{
                    margin: "auto",
                    display: "block",
                    width: "90%",
                    textAlign: "center",
                  }}
                >
                  <h3 style={{ textAlign: "left", marginBottom: "1rem" }}>
                    {/* Filter By Price */}
                  </h3>
                  {/* <SideFilter /> */}
                  <Range
                    step={5}
                    min={0}
                    max={500}
                    values={values}
                    onChange={(values) => {
                      rangeSelector(values);
                    }}
                    onFinalChange={(values) => setMinMax(values)}
                    renderTrack={({ props, children }) => (
                      <div
                        {...props}
                        style={{
                          ...props.style,
                          height: "6px",
                          width: "100%",
                          backgroundColor: "#ccc",
                          color: "#f4911d",
                        }}
                      >
                        {children}
                      </div>
                    )}
                    renderThumb={({ props }) => (
                      <div
                        {...props}
                        style={{
                          ...props.style,
                          height: "20px",
                          width: "20px",
                          borderRadius: "50%",
                          backgroundColor: "#f4911d",
                          cursor: "pointer",
                          color: "#f4911d",
                          outlineColor: "#ccc",
                        }}
                      />
                    )}
                  />
                  <br />
                  <div
                    style={{
                      marginTop: "-10px",
                      color: "#f4911d",
                      fontWeight: "bold",
                    }}
                  >
                    {values[0].toFixed(1)} - {values[1].toFixed(1)}
                  </div>
                </div>
                <div className="CATEGORIES">
                  <div onClick={() => handleCategoryFilter("None")}>
                    <FontAwesomeIcon icon={faList} />
                    الفئات
                  </div>
                </div>
                <div className="all-cat">
                  {categories.map((category) => (
                    <div
                      onClick={() => {
                        handleCategoryFilter(category.id);
                        HandleCatColorFun(category.id);
                      }}
                      style={
                        hangleCatColor === category.id
                          ? bgColorActive
                          : bgColornoAvtive
                      }
                      key={category.id}
                      id="categ-bg"
                    >
                      <p>{category.name_ar}</p>
                      <i class="fa-solid fa-chevron-right"></i>
                    </div>
                  ))}
                </div>
                <div className="best-sellers">
                  <h4>
                    <i class="fa-solid fa-bookmark"></i>الأكثر مبيعا
                  </h4>
                  {bestSellers.map((el) => (
                    <BestSellers el={el} />
                  ))}
                </div>
              </div>

              {/* Books Section */}

              <main className="all-books">
                <article>
                  <header>
                    <div>
                      <div className="show-display">
                        <button
                          className="column-book active"
                          id="column-book"
                          onClick={handleColumnBtn}
                        >
                          <i class="fa-solid fa-table-cells-large"></i>
                        </button>
                        <button
                          className="row-book"
                          id="row-book"
                          onClick={handleRowBtn}
                        >
                          <i class="fa-solid fa-list"></i>
                        </button>
                      </div>
                      <div className="sort">
                        <select onChange={sortDateHandler}>
                          <option value="-creation_date">الاحدث</option>
                          <option value="creation_date">الاقدم</option>
                        </select>
                        <p>الترتيب ب</p>
                        <select onChange={sortHandler}>
                          <option value="title_en">A-Z</option>
                          <option value="-title_en">Z-A</option>
                        </select>
                        <p>العرض</p>
                      </div>
                      <div className="pages-num">
                        <Stack spacing={2}>
                          <Pagination
                            count={pages}
                            page={pageNumber}
                            onChange={handlePageChange}
                            variant="outlined"
                            shape="rounded"
                          />
                        </Stack>
                      </div>
                    </div>
                  </header>

                  <section className="row">
                    {products.map((product) => (
                      <BookC
                        key={product.id}
                        isList={isRow}
                        title={product.title_ar}
                        price={product.price_in_USD}
                        priceT={product.price_in_TL}
                        
                        image={product.image}
                        id={product.id}
                        description={product.description_ar}
                      />
                    ))}
                  </section>
                </article>
              </main>
            </div>
          </div>
          <SaleProduct data={banner} />
          <Faqs faqs={faqs} />
          <Footer />
        </>
      )}
      {error && <p className="alert alert-danger">{error}</p>}
    </>
  );
};

export default ArHomepage;