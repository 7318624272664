import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, removeFromCart } from "../redux/cart";
import ProductSlider from "./ProductSlider";
// import UseGeo from "./UseGeo";

import useGeoLocation from "react-ipgeolocation";

const MyVerticallyCenteredModal = ({
  onHide,
  show,
  description,
  image,
  title,
  price,
  id,
  priceT,
}) => {
  const dispatch = useDispatch();

  const [count, setCount] = useState(1);

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const addToCartHandler1 = () => {
    let existingProduct = cartItems.find((x) => x.product === id);
    if (!existingProduct) {
      dispatch(addToCart(id, count));
    }

    if (existingProduct) {
      dispatch(addToCart(id, count));
      setCount(count + 1);
    }
  };

  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {}, [cartItems, id, count]);

  const [handleOpenAnimate, setHandleOpenAnimate] = useState(false);
  const [flag, setFlag] = useState(true);
  const hangleOpenAnimate = (id) => {
    setHandleOpenAnimate(true);
    setFlag(false);
  };

  const [existProduct, setExistProduct] = useState(null);

  useEffect(() => {
    let product = cartItems.find((x) => x.product === id);
    if (!product) {
      setHandleOpenAnimate(false);
    }
    setExistProduct(product);
  }, [existProduct, id, cartItems]);

  // const { countryName } = UseGeo();

  // useEffect(() => {}, [countryName]);

  const location = useGeoLocation();

  return (
    <>
      <Modal
        onHide={onHide}
        show={show}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // className="product_modal_AR"
        className="product_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Product Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProductSlider id={id} image1={image.length <= 0 ? <></> : image} />
          <div className="modal_Book_container">
            <div>{/* <img src={image} alt="" /> */}</div>
            <div
              style={{ width: "100%", overflowY: "scroll", maxHeight: "200px" }}
            >
              <h3>{title}</h3>
              <h5>{location.country === "TR" ? priceT + "TL" : "$" + price}</h5>
              <p>{description}</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{
            justifyContent: "space-between",
          }}
        >
          <Button onClick={onHide}>Close</Button>
          <div
            className="buttons"
            style={{
              position: "relative",
              cursor: "pointer",
              width: "200px",
              height: "50px",
              textAlign: "center",
            }}
          >
            <div
              className={
                handleOpenAnimate ? "trapdoor active_status" : "trapdoor"
              }
              onClick={() => {
                hangleOpenAnimate(1);
                flag ? addToCartHandler1() : console.log("test");
              }}
              style={{
                marginLeft: "0",
                bottom: "0",
                left: "auto",
                top: "auto",
                right: "0",
                borderRadius: "8px",
              }}
            >
              <div
                class="top door"
                style={{
                  backgroundColor: "#f3911d",
                  borderRadius: "8px 8px 0 0",
                }}
              ></div>
              <div
                class="bottom door"
                style={{
                  backgroundColor: "#f3911d",
                  borderRadius: "0px 0px 8px 8px",
                }}
              ></div>
              <div
                style={{
                  marginTop: ".8rem",
                  fontWeight: "600",
                  color: "#f4911d",
                }}
              >
                ADDED TO CART
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const BestSellers = ({ el }) => {
  const [modalShow, setModalShow] = useState(false);
  // const { countryName } = UseGeo();

  // useEffect(() => {}, [countryName]);

  const location = useGeoLocation();

  return (
    <>
      <div
        key={el.product.id}
        style={{
          cursor: "pointer",
        }}
        onClick={() => setModalShow(true)}
      >
        <img
          src={el.product.image}
          alt={el.product.title_en}
          style={{
            maxHeight: "100px",
            minHeight: "99px",
          }}
        />
        <div>
          <p>
            {el.product.title_en.length > 7
              ? el.product.title_en.slice(0, 7) + "..."
              : el.product.title_en}
          </p>
          <span>
            {location.country === "TR"
              ? el.product.price_in_TL + "TL"
              : "$" + el.product.price_in_USD}
          </span>
        </div>
      </div>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        description={el.product.description_en}
        image={el.product.image}
        title={el.product.title_en}
        price={el.product.price_in_USD}
        priceT={el.product.price_in_TL}
        id={el.id}
      />
    </>
  );
};

export default BestSellers;
