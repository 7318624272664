import React from "react";
import { Accordion, Button } from "react-bootstrap";

const Faqs = ({ faqs }) => {
  return (
    <>
      <div className="container faq-container mb-5">
        <h3
          className="mb-5"
          style={{ color: "#f6921e", textAlign: "center", letterSpacing: "0" }}
        >
          FAQS
        </h3>
        <Accordion className="Accordion" style={{ borderRadius: "10px" }}>
          {/* <Accordion className="arAccordion" style={{ backgroundColor: "#f6921e", borderRadius: '10px' }}> */}
          {faqs.map((faq) => (
            <Accordion.Item
              key={faq.id}
              eventKey={faq.id}
              style={{ borderRadius: "10px" }}
            >
              <Accordion.Header
                style={{
                  backgroundColor: "#f6921e",
                  fontSize: "28px",
                  borderRadius: "10px",
                }}
              >
                <h4
                  style={{
                    color: "gray",
                    letterSpacing: "0",
                    // textAlign: "right",
                    fontWeight: "300",
                  }}
                >
                  {faq.question_en}
                </h4>
              </Accordion.Header>
              <Accordion.Body style={{ borderRadius: "10px" }}>
                <>
                  <div className="row">
                    <p
                      style={{ letterSpacing: "0", whiteSpace: "break-spaces" }}
                    >
                      {faq.answer_en}{" "}
                    </p>
                  </div>
                </>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </>
  );
};

export default Faqs;
