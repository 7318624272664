import axios from 'axios'
const POST_FORM_REQUEST = "POST_FORM_REQUEST"
const POST_FORM_SUCCESS = "POST_FORM_SUCCESS"
const POST_FORM_FAIL = "POST_FORM_FAIL"

export const postFormAction = (form) => async (dispatch) => {
    try {
        dispatch({
            type: POST_FORM_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }

        const { data } = await axios.post(
            'https://provada-store.herokuapp.com/api/contact/',
            form,
            config
        )

        dispatch({
            type: POST_FORM_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: POST_FORM_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const postFormReducer = (state = {}, action) => {
    switch (action.type) {
        case POST_FORM_REQUEST:
            return { loading: true }

        case POST_FORM_SUCCESS:
            return { loading: false, success: action.payload }

        case POST_FORM_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}