import React, { useState } from "react";

import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../arComponents/Footer";
import Navbar from "../arComponents/NavBar";
import { postFormAction } from "../redux/contact";

import imgtest from "../Assets/images/contact.03d0181f.gif";

import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ArContactUs = ({ history }) => {
  const dispatch = useDispatch();

  const postForm = useSelector((state) => state.postForm);
  const { success } = postForm;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const submitHandler = (e) => {
    e.preventDefault();
    const form = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      message: message,
    };
    dispatch(postFormAction(form));

    if (success) {
      toast.success("Form Submitted Successfully!", {
        autoClose: 500,
        transition: Slide,
      });
    }

    setFirstName("");
    setLastName("");
    setEmail("");
    setMessage("");
  };

  return (
    <>
      <Navbar history={history} />

      <div className="registration-steps-band artboard">
        <h2 style={{ textAlign: "center" }}>كن على تواصل معنا</h2>
      </div>
      <ToastContainer
        style={{ boxShadow: "none" }}
        newestOnTop={true}
        hideProgressBar={true}
      />
      <div className="container mb-5 artboard">
        <div className="row mt-5">
          <div className="col-md-6">
            <img src={imgtest} alt="" className="img-fluid" />
          </div>

          <div className="col-md-6 d-flex">
            <div className="container m-auto">
              <Form onSubmit={submitHandler}>
                <div class="input-group mb-3">
                  <input
                    required
                    dir="rtl"
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    class="form-control"
                    placeholder="الاسم الاول"
                    aria-label="الاسم الاول"
                    style={{ marginRight: "1rem" }}
                  />

                  <input
                    required
                    dir="rtl"
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    class="form-control"
                    placeholder="اسم العائلة"
                    aria-label="اسم العائلة"
                  />
                </div>

                <div class="input-group mb-3">
                  <input
                    required
                    dir="rtl"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="البريد الإلكتروني"
                    class="form-control"
                    aria-label="البريد الإلكتروني"
                  />
                </div>

                <div class="input-group">
                  <textarea
                    required
                    dir="rtl"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="الرسالة"
                    class="form-control"
                    aria-label="الرسالة"
                  ></textarea>
                </div>

                <button
                  type="submit"
                  className="btn-contact mt-3"
                  style={{ border: "none " }}
                >
                  إرسال
                </button>
              </Form>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container-fluid contact-card-fluid1 artboard" >
                <div className="row contact-card-row1">
                    <div className="col-md-6 contact-card-col1">
                        <div className="row contact-card-row2">
                            <div className="col-md-6 contact-card-col2">
                                <div className="container-fluid contact-card-fluid2">
                                    <p style={{ color: "#000000", marginTop: "20px" }} className="footer-data"><i style={{ marginRight: "5px", color: "#ed9835" }} class="fas fa-phone-alt"></i><a style={{ textDecoration: "none", color: "#000000" }} target="_blank" href="tel:+902128554253"> +90 212 855 42 53</a></p>
                                    <p style={{ color: "#000000" }} className="footer-data"><i style={{ marginRight: "5px", color: "#ed9835" }} class="fas fa-envelope"></i><a style={{ textDecoration: "none", color: "#000000" }} target="_blank" href="mailto:info@provada.education">info@provada.education</a></p>
                                    <p style={{ color: "#000000" }} className="footer-data"><i style={{ marginRight: "5px", color: "#ed9835" }} class="fas fa-home"><a style={{ textDecoration: "none", color: "#000000", fontSize: '12px', letterSpacing: '3px', lineHeight: '20px' }} target="_blank" href="https://goo.gl/maps/fbCPABmanRuE5eMh7"> Babacan Premium B blok / 387</a></i></p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div> */}

      <Footer />
    </>
  );
};

export default ArContactUs;
