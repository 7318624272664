import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, removeFromCart } from "../redux/cart";
import { Link } from "react-router-dom";

import { Button, Modal } from "react-bootstrap";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProductSlider from "../Components/ProductSlider";
import axios from "axios";
import useGeoLocation from "react-ipgeolocation";

const MyVerticallyCenteredModal = ({
  id,
  onHide,
  show,
  description,
  image,
  title,
  price,
  priceT,
}) => {
  const dispatch = useDispatch();

  const [count, setCount] = useState(1);

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  // useEffect(() => {}, [isList]);

  // const item = cartItems.find((x) => x.product === id);

  // const addToCartHandler = (id) => {
  //   const existingProduct = cartItems.find((x) => x.product === id);
  //   if (existingProduct) {
  //     dispatch(addToCart(id, existingProduct.qty + 1));
  //   } else {
  //     dispatch(addToCart(id, count));
  //   }
  // };

  const addToCartHandler1 = () => {
    let existingProduct = cartItems.find((x) => x.product === id);
    if (!existingProduct) {
      dispatch(addToCart(id, count));
    }

    if (existingProduct) {
      dispatch(addToCart(id, count));
      setCount(count + 1);
    }
  };

  // const existingProduct = cartItems.find((x) => x.product === id);
  // if (existingProduct) {
  //   if (existingProduct.qty === 0) {
  //     setHandleOpenAnimate(false);
  //   } else {
  //     setHandleOpenAnimate(true);
  //   }
  // }

  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {}, [cartItems, id, count]);

  const [handleOpenAnimate, setHandleOpenAnimate] = useState(false);
  const [flag, setFlag] = useState(true);
  const hangleOpenAnimate = (id) => {
    setHandleOpenAnimate(true);
    setFlag(false);
  };

  // const removeFromCartHandler = (id) => {
  //   let existingProduct = cartItems.find((x) => x.product === id);
  //   if (existingProduct) {
  //     if (existingProduct.qty === 1) {
  //       dispatch(removeFromCart(id));
  //     } else {
  //       dispatch(addToCart(id, existingProduct.qty - 1));
  //     }
  //   }
  // };

  const [existProduct, setExistProduct] = useState(null);

  useEffect(() => {
    let product = cartItems.find((x) => x.product === id);
    if (!product) {
      setHandleOpenAnimate(false);
    }
    setExistProduct(product);
  }, [existProduct, id, cartItems]);

  // const { countryName } = UseGeo();

  // useEffect(() => {}, [countryName]);

  const location = useGeoLocation();

  return (
    <>
      <Modal
        onHide={onHide}
        show={show}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // className="product_modal"
        className="product_modal_AR"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            تفاصيل المنتج
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProductSlider id={id} image1={image.length <= 0 ? <></> : image} />
          <div className="modal_Book_container">
            <div>{/* <img src={image} alt="" /> */}</div>
            <div style={{ overflowY: "scroll", maxHeight: "200px" }}>
              <h3>{title}</h3>
              <h5 style={{ fontSize: "17px" }}>
                {location.country === "TR" ? priceT + "TL" : "$" + price}
              </h5>
              <p>{description}</p>
              <div
                className="buttons"
                style={{
                  position: "relative",
                  cursor: "pointer",
                  width: "200px",
                  height: "50px",
                  textAlign: "center",
                  marginTop: "2rem",
                }}
              ></div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide}>إغلاق</Button>
          <div
            className={
              handleOpenAnimate ? "trapdoor active_status" : "trapdoor"
            }
            onClick={() => {
              hangleOpenAnimate(1);
              flag ? addToCartHandler1() : console.log("test");
            }}
            style={{
              marginLeft: "0",
              bottom: "0",
              left: "auto",
              top: "auto",
              right: "0",
              marginBottom: "10px",
              marginRight: "10px",
              borderRadius: "8px",
            }}
          >
            <div
              class="top door"
              style={{
                backgroundColor: "#f3911d",
                borderRadius: "8px 8px 0 0",
              }}
            ></div>
            <div
              class="bottom door"
              style={{
                backgroundColor: "#f3911d",
                borderRadius: "0px 0px 8px 8px",
              }}
            ></div>
            {/* <div
                style={{ display: "flex", alignItems: "center" }}
                className="card_qty"
              >
                <button onClick={() => addToCartHandler(id)}>
                  <div style={{ width: "100%" }}>
                    <span style={{ height: "26px" }}>+</span>
                  </div>
                </button>
                <p style={{ marginBottom: "0" }}>{existProduct?.qty}</p>
                <button onClick={() => removeFromCartHandler(id)}>
                  <div style={{ width: "100%" }}>
                    <span style={{ height: "26px" }}>-</span>
                  </div>
                </button>
              </div> */}
            <div
              style={{
                marginTop: ".8rem",
                fontWeight: "600",
                color: "#f4911d",
                textAlign: "center",
              }}
            >
              أضف الى السلة
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const Book = ({ id, isList, title, price, image, description, priceT }) => {
  const dispatch = useDispatch();

  const [count, setCount] = useState(1);

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  useEffect(() => {}, [isList]);

  const addToCartHandler1 = () => {
    let existingProduct = cartItems.find((x) => x.product === id);
    if (!existingProduct) {
      dispatch(addToCart(id, count));
    }

    if (existingProduct) {
      dispatch(addToCart(id, count));
      setCount(count + 1);
    }
  };

  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {}, [cartItems, id, count]);

  const [handleOpenAnimate, setHandleOpenAnimate] = useState(false);
  const [flag, setFlag] = useState(true);
  const hangleOpenAnimate = (id) => {
    setHandleOpenAnimate(true);
    setFlag(false);
  };

  const [existProduct, setExistProduct] = useState(null);

  useEffect(() => {
    let product = cartItems.find((x) => x.product === id);
    if (!product) {
      setHandleOpenAnimate(false);
    } else {
      setHandleOpenAnimate(true);
    }
    setExistProduct(product);
  }, [existProduct, id, cartItems]);

  // const { countryName } = UseGeo();

  // useEffect(() => {}, [countryName]);

  const location = useGeoLocation();

  return (
    <>
      <div className={isList ? "Book row-one" : "Book"}>
        <div className="container">
          <img src={image} alt={title} onClick={() => setModalShow(true)} />
          <hr style={{ width: "100%" }} />
          <Link style={{ textDecoration: "none" }}>
            <h2 onClick={() => setModalShow(true)}>
              {title.length > 7 ? "..." + title.slice(0, 7) : title}
            </h2>
          </Link>
          <div className="price">
            <p style={{ fontSize: "17px", color: "gray" }}>
              {location.country === "TR" ? priceT + "TL" : "$" + price}
            </p>
            <p></p>
          </div>
          <div
            className="buttons"
            style={{ position: "relative", cursor: "pointer" }}
          >
            <div
              className={
                handleOpenAnimate ? "trapdoor active_status" : "trapdoor"
              }
              onClick={() => {
                hangleOpenAnimate(1);
                flag ? addToCartHandler1() : console.log("test");
              }}
            >
              <div
                class="top door"
                style={{ backgroundColor: "#f3911d" }}
              ></div>
              <div
                class="bottom door"
                style={{ backgroundColor: "#f3911d" }}
              ></div>
              <div
                style={{
                  marginTop: ".8rem",
                  fontWeight: "600",
                  color: "#f4911d",
                  textAlign: "center",
                }}
              >
                تمت الإضافة الى السلة
              </div>
            </div>
          </div>
          <MyVerticallyCenteredModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            description={description}
            image={image}
            title={title}
            price={price}
            priceT={priceT}
          />
        </div>
        <ToastContainer
          style={{ boxShadow: "none" }}
          newestOnTop={true}
          hideProgressBar={true}
        />
      </div>
    </>
  );
};

export default Book;
