import axios from "axios";
import React, { useEffect, useState } from "react";
import BestSellers from "./BestSellers";

const UseGeo = () => {
    const [countryName, setCountryName] = useState("");
    const [countryCode, setCountryCode] = useState("");

    let accesskey =
        "http://api.ipapi.com/151.250.212.229?access_key=203e68e9c7078d06c1d895fe4bee7680";

    const getGeoInfo = () => {
        axios
            .get(accesskey)
            .then((response) => {
                let data = response.data;
                setCountryName(data.country_name);
                setCountryCode(data.country_calling_code);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        getGeoInfo();
    }, []);

    return { countryName };
};

export default UseGeo;