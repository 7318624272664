import React from 'react'
import { Link } from 'react-router-dom'

import logo from '../Assets/images/logo-01.png'

import pay1 from '../Assets/images/payicons-01.jpg'
import pay2 from '../Assets/images/payicons-02.jpg'
import pay3 from '../Assets/images/payicons-03.jpg'
import pay4 from '../Assets/images/payicons-04.jpg'

const Footer = () => {

    return (
        <>
            <div className="footer" style={{ marginTop: '5rem' }}>
                {/* <div className="footer-AR" style={{marginTop: '5rem'}}> */}
                {/* <div className="container">
                    <div className='footer-1'>
                        <ul>
                            <b>My account</b>
                            <br />
                            <li><a href=""><i className="fa-solid fa-caret-right"></i> My orders</a></li>
                            <li><a href=""><i className="fa-solid fa-caret-right"></i> My credit slips</a></li>
                            <li><a href=""><i className="fa-solid fa-caret-right"></i> My addresses</a></li>
                            <li><a href=""><i className="fa-solid fa-caret-right"></i> My personal info</a></li>
                            <li><a href=""><i className="fa-solid fa-caret-right"></i> My vouchers</a></li>
                        </ul>
                        <ul>
                            <b>Information</b>
                            <li><Link to="/contact"><i className="fa-solid fa-caret-right"></i> Contact</Link></li>
                            <li><a href=""><i className="fa-solid fa-caret-right"></i> Privacy Policy</a></li>
                            <li><a href=""><i className="fa-solid fa-caret-right"></i> Customer Policy</a></li>
                            <li><Link href="/about"><i className="fa-solid fa-caret-right"></i> About</Link></li>
                        </ul>
                        <ul>
                            <b>Universities</b>
                            <li><a href=""><i className="fa-solid fa-caret-right"></i> About us</a></li>
                            <li><a href=""><i className="fa-solid fa-caret-right"></i> SERVICES</a></li>
                            <li><a href=""><i className="fa-solid fa-caret-right"></i> SUCCESS STORIES</a></li>
                            <li><a href=""><i className="fa-solid fa-caret-right"></i> UNIVERSITIES</a></li>
                            <li><a href=""><i className="fa-solid fa-caret-right"></i> REGISTRATION</a></li>
                        </ul>
                        <ul>
                            <b>Why Choose Us</b>
                            <li><a href=""><i className="fa-solid fa-caret-right"></i> About us</a></li>
                            <li><a href=""><i className="fa-solid fa-caret-right"></i> Company</a></li>
                            <li><a href=""><i className="fa-solid fa-caret-right"></i> Product</a></li>
                        </ul>
                        <ul>
                            <b>Contact Us</b>
                            <li><a href="tel: +90 212 855 42 53" ><i className="fa-solid fa-phone"></i> +90 212 855 42 53</a></li>
                            <li><a href="mailto:info@provada.education"><i className="fa-solid fa-envelope"></i> info@provada.education</a></li>
                            <li><a href="https://www.google.com/maps/place/Babacan+Premium/@41.006567,28.682864,14z/data=!4m5!3m4!1s0x0:0x3c09aa1320cb2047!8m2!3d41.0078215!4d28.6816046?hl=en" target="_blank"><i className="fa-solid fa-link"></i> Babacan Premium B blok / 387</a></li>
                            <li><a href="https://www.provada.education/#/" target="_blank"><i className="fa-solid fa-house"></i> www.provada.education</a></li>
                            <div className='payment-methods'>
                                <img src={pay1} alt="" style={{ height: '35px', margin: '0.2rem', borderRadius: '5px' }} />
                                <img src={pay2} alt="" style={{ height: '35px', margin: '0.2rem', borderRadius: '5px' }} />
                                <img src={pay3} alt="" style={{ height: '35px', margin: '0.2rem', borderRadius: '5px' }} />
                                <img src={pay4} alt="" style={{ height: '35px', margin: '0.2rem', borderRadius: '5px' }} />
                            </div>
                        </ul>
                    </div>
                </div> */}

                <div className='container'>
                    <div className="footer-1">
                        <div>
                            <img width={150} src={logo} alt="" />
                            <ul>
                                <b>Information</b>
                                <li><Link to="/contact"><i className="fa-solid fa-caret-right"></i> Contact</Link></li>
                                <li><a href=""><i className="fa-solid fa-caret-right"></i> Privacy Policy</a></li>
                                <li><a href=""><i className="fa-solid fa-caret-right"></i> Customer Policy</a></li>
                                <li><Link href="/about"><i className="fa-solid fa-caret-right"></i> About</Link></li>
                            </ul>
                            {/* <ul>
                            <b>Universities</b>
                            <li><a href=""><i className="fa-solid fa-caret-right"></i> About us</a></li>
                            <li><a href=""><i className="fa-solid fa-caret-right"></i> SERVICES</a></li>
                            <li><a href=""><i className="fa-solid fa-caret-right"></i> SUCCESS STORIES</a></li>
                            <li><a href=""><i className="fa-solid fa-caret-right"></i> UNIVERSITIES</a></li>
                            <li><a href=""><i className="fa-solid fa-caret-right"></i> REGISTRATION</a></li>
                        </ul> */}
                            {/* <ul>
                            <b>Why Choose Us</b>
                            <li><a href=""><i className="fa-solid fa-caret-right"></i> About us</a></li>
                            <li><a href=""><i className="fa-solid fa-caret-right"></i> Company</a></li>
                            <li><a href=""><i className="fa-solid fa-caret-right"></i> Product</a></li>
                        </ul> */}
                            <ul>
                                <b>Contact Us</b>
                                <li><a href="tel: +90 212 855 42 53" ><i className="fa-solid fa-phone"></i> +90 212 855 42 53</a></li>
                                <li><a href="mailto:info@provada.education"><i className="fa-solid fa-envelope"></i> info@provada.education</a></li>
                                <li><a href="https://www.google.com/maps/place/Babacan+Premium/@41.006567,28.682864,14z/data=!4m5!3m4!1s0x0:0x3c09aa1320cb2047!8m2!3d41.0078215!4d28.6816046?hl=en" target="_blank"><i className="fa-solid fa-link"></i> Babacan Premium B blok / 387</a></li>
                                <li><a href="https://www.provada.education/#/" target="_blank"><i className="fa-solid fa-house"></i> www.provada.education</a></li>
                                {/* <div className='payment-methods'>
                                <img src={pay1} alt="" style={{ height: '35px', margin: '0.2rem', borderRadius: '5px' }} />
                                <img src={pay2} alt="" style={{ height: '35px', margin: '0.2rem', borderRadius: '5px' }} />
                                <img src={pay3} alt="" style={{ height: '35px', margin: '0.2rem', borderRadius: '5px' }} />
                                <img src={pay4} alt="" style={{ height: '35px', margin: '0.2rem', borderRadius: '5px' }} />
                            </div> */}
                            </ul>
                            {/* <div className='footer-1'>
                        //      <ul>
                        //     <b>My account</b>
                        //     <br />
                        //     <li><a href=""><i className="fa-solid fa-caret-right"></i> My orders</a></li>
                        //     <li><a href=""><i className="fa-solid fa-caret-right"></i> My credit slips</a></li>
                        //     <li><a href=""><i className="fa-solid fa-caret-right"></i> My addresses</a></li>
                        //     <li><a href=""><i className="fa-solid fa-caret-right"></i> My personal info</a></li>
                        //     <li><a href=""><i className="fa-solid fa-caret-right"></i> My vouchers</a></li>
                        // </ul>
                            
                        </div> */}
                        </div>
                    </div>
                </div>

                <div className='footer-3'>
                    <div className="container">
                        {/* <b>Hot Tags</b>
                        <p>Mini hobby tool kit case iphone reflective kingston micro sd card 8gb led tail light splants vs zombies figures the best smartphone repair parts replacement touch screen digitizer for gps halloween
                            flatware 32gb tf card mercedes grille badges travel clock bluetooth motorcycle headset ramos lcd tablet pc black nike headband best mini usb flash drive coolest headphones mini gps android smart
                            stickfenix tk40 flashlight</p> */}
                        <div style={{ minWidth: '100%' }}>
                            <p style={{ textAlign: 'center', width: '100%' }}>Provada © 2022. All Rights Reserved.</p>
                            {/* <div>
                            <div>
                                <a href="https://twitter.com/ProvadaE" target="_blank"><i className="fa-brands fa-twitter-square"></i></a>
                                <a href="https://www.facebook.com/provada.edu/"  target="_blank"><i className="fa-brands fa-facebook-square"></i></a>
                                <a href="https://www.instagram.com/provada_education/"  target="_blank"><i className="fa-brands fa-instagram-square"></i></a>
                                <a href="https://www.youtube.com/channel/UCVQUvTaMEBNcIaGP4lh5e9A/featured"  target="_blank"><i className="fa-brands fa-youtube-square"></i></a>
                            </div>
                        </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer