import React from "react";
import { Link } from "react-router-dom";

import logo from "../Assets/images/logo-01.png";

const Footer = () => {
  return (
    <>
      <div className="footer-AR" style={{ marginTop: "5rem" }}>
        <div className="container">
          <div className="footer-1">
            <div>
              <img width={150} src={logo} alt="" />
              <ul>
                <b>معلومات عنا</b>
                <li>
                  <Link to="/AR/contact">
                    <i className="fa-solid fa-caret-right"></i> تواصل
                  </Link>
                </li>
                <li>
                  <Link to="/AR/homepage">
                    <i className="fa-solid fa-caret-right"></i> سياسة الخصوصية
                  </Link>
                </li>
                <li>
                  <Link to="/AR/homepage">
                    <i className="fa-solid fa-caret-right"></i> سياسة العميل
                  </Link>
                </li>
                <li>
                  <Link to="/AR/about">
                    <i className="fa-solid fa-caret-right"></i> من نحن
                  </Link>
                </li>
              </ul>
              <ul>
                <b>معلومات التواصل</b>
                <li>
                  <a href="tel: +90 212 855 42 53">
                    <i className="fa-solid fa-phone"></i> +90 212 855 42 53
                  </a>
                </li>
                <li>
                  <a href="mailto:info@provada.education">
                    <i className="fa-solid fa-envelope"></i>{" "}
                    info@provada.education
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.google.com/maps/place/Babacan+Premium/@41.006567,28.682864,14z/data=!4m5!3m4!1s0x0:0x3c09aa1320cb2047!8m2!3d41.0078215!4d28.6816046?hl=en"
                    target="_blank"
                  >
                    <i className="fa-solid fa-link"></i> Babacan Premium B blok
                    / 387
                  </a>
                </li>
                <li>
                  <a href="https://www.provada.education/#/" target="_blank">
                    <i className="fa-solid fa-house"></i> www.provada.education
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="footer-3">
          <div className="container">
            <div style={{ minWidth: "100%" }}>
              <p style={{ textAlign: "center", width: "100%" }}>
                Provada © 2022. جميع الحقوق محفوظة
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
