import React, { useEffect, useState } from "react";
import {
  Navbar,
  Container,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Modal,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import ReactWhatsapp from "react-whatsapp";
import logo from "../Assets/images/logo-01.png";
// import Book from "../Assets/images/book.png";
// import Stationery from "../Assets/images/";
import langen from "../Assets/images/lang-03.png";
import langar from "../Assets/images/lang-04.png";

import { NavLink, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, removeFromCart } from "../redux/cart";
import { productsListAction } from "../redux/products";
import axios from "axios";
import UseGeo from "./UseGeo";

import useGeoLocation from "react-ipgeolocation";

const NavBar = ({
  addthecolor1,
  addthecolor2,
  addthecolor3,
  history,
  // categories,
}) => {
  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const [prodCount, setProdCount] = useState(1);

  const addToCartHandler = (id) => {
    const existingProduct = cartItems.find((x) => x.product === id);
    if (existingProduct) {
      dispatch(addToCart(id, existingProduct.qty + 1));
    } else {
      dispatch(addToCart(id, prodCount));
    }
  };

  const removeFromCartHandler = (id) => {
    const existingProduct = cartItems.find((x) => x.product === id);
    if (existingProduct) {
      if (existingProduct.qty === 1) {
        dispatch(removeFromCart(id));
      } else {
        dispatch(addToCart(id, existingProduct.qty - 1));
      }
    }
  };

  const binHandler = (id) => {
    dispatch(removeFromCart(id));
  };

  const [keyword, setKeyword] = useState("");

  const searchFilter = (e) => {
    e.preventDefault();
    setKeyword(e.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      productsListAction(
        null,
        "title_en",
        "-creation_date",
        keyword,
        1,
        500,
        "None"
      )
    );
    history.push(`/homepage?q=${keyword}`);
  };

  const handleCheckout = () => {
    dispatch({
      type: "CART_CLEAR_ITEMS",
    });
    localStorage.removeItem("cartItems");
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const { countryName } = UseGeo();

  // useEffect(() => {}, [countryName]);

  const location = useGeoLocation();

  return (
    <>
      <Navbar bg="light" expand="lg" className="main-nav">
        {/* <Navbar bg="light" expand="lg" className="main-nav-AR"> */}
        <Container>
          <Nav
            className="me-auto my-2 my-lg-0 nav-text"
            style={{ maxHeight: "100px", margin: "auto" }}
            navbarScroll
          >
            <p>Welcome to Provada Online Store</p>
          </Nav>
          <Form className="d-flex">
            <DropdownButton id="dropdown-basic-button" title="Languages">
              <Dropdown.Item href="#">
                <img src={langen} alt="" style={{ maxWidth: "30px" }} /> English
              </Dropdown.Item>
              <Dropdown.Item href="#/AR/homepage">
                <img src={langar} alt="" style={{ maxWidth: "30px" }} /> Arabic
              </Dropdown.Item>
            </DropdownButton>
          </Form>
        </Container>
      </Navbar>

      {/* <Navbar
        bg="light"
        expand="lg"
        className="second-nav"
        style={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      > */}
      <Navbar
        bg="light"
        expand="lg"
        // className="second-nav-AR"
        className="second-nav"
        style={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Container className="second-nav-container">
          <Link to="/homepage">
            <Navbar.Brand>
              <img src={logo} alt="" style={{ maxWidth: "150px" }} />
            </Navbar.Brand>
          </Link>

          <Container className="nav-links-my">
            <Nav
              className="me-auto my-2 my-lg-0 nav"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              {/* <NavDropdown title="PRODUCT" id="navbarScrollingDropdown" className='allproducts'>
                  <Nav.Link href="" style={{ padding: '1rem' }}> <img src={Book} alt="" style={{ maxWidth: '20px', minWidth: '19px', maxHeight: '31px', minHeight: '30px' }} /> books</Nav.Link>
                  <Nav.Link href="" style={{ padding: '1rem' }}> <img src={Book} alt="" style={{ maxWidth: '20px', minWidth: '19px', maxHeight: '31px', minHeight: '30px' }} /> books</Nav.Link>
                  <Nav.Link href="" style={{ padding: '1rem' }}> <img src={Book} alt="" style={{ maxWidth: '20px', minWidth: '19px', maxHeight: '31px', minHeight: '30px' }} /> books</Nav.Link>
                  <Nav.Link href="" style={{ padding: '1rem' }}> <img src={Book} alt="" style={{ maxWidth: '20px', minWidth: '19px', maxHeight: '31px', minHeight: '30px' }} /> books</Nav.Link>
              </NavDropdown> */}
              <Nav.Link href="/#homepage">
                <NavLink
                  to="/homepage"
                  style={{ textDecoration: "none", color: "gray" }}
                  className={addthecolor1}
                >
                  Home
                </NavLink>
              </Nav.Link>
              <Nav.Link href="/#about">
                <NavLink
                  to="/about"
                  style={{ textDecoration: "none", color: "gray" }}
                  className={addthecolor2}
                >
                  About us
                </NavLink>
              </Nav.Link>
              <Nav.Link href="/#contact">
                <NavLink
                  to="/contact"
                  style={{ textDecoration: "none", color: "gray" }}
                  className={addthecolor3}
                >
                  Contact us
                </NavLink>
              </Nav.Link>
            </Nav>
          </Container>
          {/* <Form onSubmit={submitHandler} className="d-flex second-nav-form">
            <NavDropdown
              className="allCategories"
              title="All Categories"
              id="navbarScrollingDropdown"
            >
              <Nav.Link
                onClick={() => {
                  dispatch(
                    productsListAction(
                      null,
                      "title_en",
                      "-creation_date",
                      "",
                      1,
                      500,
                      "Stationery"
                    )
                  );
                  history.push("/homepage?category=Stationery");
                }}
                style={{ padding: ".5rem" }}
              >
                {" "}
                <img
                  src={Stationery}
                  alt=""
                  style={{
                    maxWidth: "55px",
                    minWidth: "54px",
                    maxHeight: "52px",
                    minHeight: "51px",
                  }}
                />{" "}
                Stationery
              </Nav.Link>
              <Nav.Link
                onClick={() => {
                  dispatch(
                    productsListAction(
                      null,
                      "title_en",
                      "-creation_date",
                      "",
                      1,
                      500,
                      "Books"
                    )
                  );
                  history.push("/homepage?category=Books");
                }}
                style={{ padding: "1rem" }}
              >
                {" "}
                <img
                  src={Book}
                  alt=""
                  style={{
                    maxWidth: "55px",
                    minWidth: "54px",
                    maxHeight: "52px",
                    minHeight: "51px",
                  }}
                />{" "}
                Books
              </Nav.Link>
            </NavDropdown>
            <FormControl
              type="search"
              placeholder="Enter keywords here..."
              className="me-2"
              aria-label="Search"
              onChange={searchFilter}
            />
            <Button variant="outline-success">
              <i class="fa-solid fa-magnifying-glass"></i>
            </Button>
          </Form> */}
          <div className="sign-in">
            {/* <Link to='/signin'><button>SIGN IN <i class="fa-solid fa-user"></i></button></Link> */}
            <button className="cart-hover">
              <Link style={{ color: "black", textDecoration: "none" }}>
                <i className="fa-solid fa-cart-shopping mx-1"></i>
                {cartItems.length} ITEMS -{" "}
                {location.country !== "TR" ? "$" : ""}
                {cartItems
                  .map((item) =>
                    location.country === "TR"
                      ? item.price_tl * item.qty
                      : item.price_usd * item.qty
                  )
                  .reduce((a, b) => a + b, 0)}
                {location.country === "TR" ? "TL" : ""}
              </Link>
              <div>
                <div style={{ overflowY: "scroll" }}>
                  {cartItems.length === 0 ? (
                    <p>Your Cart is Empty</p>
                  ) : (
                    cartItems.map((item) => (
                      <>
                        <div className="card-holder">
                          <div>
                            <img
                              width={120}
                              height={80}
                              src={item.image}
                              alt=""
                            />
                          </div>
                          <div>
                            <h5>
                              {item.title.length > 7
                                ? item.title.slice(0, 7) + "..."
                                : item.title}
                            </h5>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <button
                              onClick={() => addToCartHandler(item.product)}
                            >
                              <div style={{ width: "100%" }}>
                                <span style={{ height: "26px" }}>+</span>
                              </div>
                            </button>
                            <p>{item.qty}</p>
                            <button
                              onClick={() =>
                                removeFromCartHandler(item.product)
                              }
                            >
                              <div style={{ width: "100%" }}>
                                <span style={{ height: "26px" }}>-</span>
                              </div>
                            </button>
                          </div>
                          <div>
                            <h4>
                              {location.country === "TR"
                                ? item.price_tl * item.qty + "TL"
                                : "$" + item.price_usd * item.qty}
                            </h4>
                            <b onClick={() => binHandler(item.product)}>
                              <i class="fa-solid fa-trash-can"></i>
                            </b>
                          </div>
                        </div>
                        {/* <hr /> */}
                      </>
                    ))
                  )}
                </div>
                <button
                  style={{
                    position: "sticky",
                    border: "none",
                    top: "0",
                    paddingTop: "1rem",
                    borderTop: "1px solid #bdbdbd",
                  }}
                  className={
                    cartItems.length === 0 ? `btn disabled checkout` : "btn"
                  }
                >
                  <button type="button" onClick={handleShow}>
                    Checkout
                  </button>

                  <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                  >
                    {/* <Modal.Header className="modal-header-AR"> */}
                    <Modal.Header className="modal-header">
                      <Modal.Title style={{ textAlign: "center" }}>
                        Checkout
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ width: "100%" }}>
                      {cartItems.length === 0 ? (
                        <p>Your Cart is Empty</p>
                      ) : (
                        cartItems.map((item) => (
                          <>
                            <div className="card-holder-modal">
                              {/* <div className="card-holder-modal-AR"> */}
                              <div>
                                <img
                                  width={120}
                                  height={80}
                                  src={item.image}
                                  alt=""
                                />
                              </div>
                              <div>
                                <h5>
                                  {item.title.length > 7
                                    ? item.title.slice(0, 7) + "..."
                                    : item.title}
                                </h5>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <button
                                  onClick={() => addToCartHandler(item.product)}
                                >
                                  <div style={{ width: "100%" }}>
                                    <span style={{ height: "26px" }}>+</span>
                                  </div>
                                </button>
                                <p>{item.qty}</p>
                                <button
                                  onClick={() =>
                                    removeFromCartHandler(item.product)
                                  }
                                >
                                  <div style={{ width: "100%" }}>
                                    <span style={{ height: "26px" }}>-</span>
                                  </div>
                                </button>
                              </div>
                              <div>
                                <h4>
                                  {location.country === "TR"
                                    ? item.price_tl * item.qty + "TL"
                                    : "$" + item.price_usd * item.qty}
                                </h4>
                                <b onClick={() => binHandler(item.product)}>
                                  <i class="fa-solid fa-trash-can"></i>
                                </b>
                              </div>
                            </div>
                            <hr />
                          </>
                        ))
                      )}
                    </Modal.Body>
                    <Modal.Footer style={{ justifyContent: "center" }}>
                      <Button variant="secondary" onClick={handleClose}>
                        Go Back
                      </Button>
                      <div onClick={handleCheckout}>
                        {cartItems.length === 0 ? (
                          <ReactWhatsapp
                            disabled
                            style={{
                              backgroundColor: "gray",
                              opacity: "0.5",
                              borderRadius: "7px",
                              padding: ".5rem 1rem",
                              border: "none",
                              color: "white",
                            }}
                            number="+905345713224"
                            message={`Hello I would like to order the following item(s).\n ${cartItems.map(
                              (x) =>
                                "Name: " +
                                  x.title +
                                  " " +
                                  "Quantity: " +
                                  x.qty +
                                  " " +
                                  `Price: ${location.country === "TR" ? "TL" : "$"
                                  }` +
                                  location.country ===
                                  "TR"
                                  ? x.price_tl
                                  : x.price_usd + "\n"
                            )}Total Price: ${""}${cartItems
                              .map((item) =>
                                location.country === "TR"
                                  ? item.price_tl * item.qty
                                  : item.price_usd * item.qty
                              )
                              .reduce((a, b) => a + b, 0)}${location.country === "TR" ? "TL" : "USD"
                              }`}
                          >
                            Order Via Whatsapp{" "}
                            <i class="fa-brands fa-whatsapp"></i>
                          </ReactWhatsapp>
                        ) : (
                          <>
                            <ReactWhatsapp
                              style={{
                                backgroundColor: "#f3911e",
                                borderRadius: "7px",
                                padding: ".5rem 1rem",
                                border: "none",
                                color: "white",
                              }}
                              number="+905345713224"
                              message={`Hello I would like to order the following item(s).\n ${cartItems.map(
                                (x) =>
                                  "Name: " +
                                    x.title +
                                    " " +
                                    "Quantity: " +
                                    x.qty +
                                    " " +
                                    `Price: ${location.country === "TR" ? "TL" : "$"
                                    }` +
                                    location.country ===
                                    "TR"
                                    ? x.price_tl
                                    : x.price_usd + "\n"
                              )}Total Price: ${""}${cartItems
                                .map((item) =>
                                  location.country === "TR"
                                    ? item.price_tl * item.qty
                                    : item.price_usd * item.qty
                                )
                                .reduce((a, b) => a + b, 0)} ${location.country === "TR" ? "TL" : "USD"
                                }`}
                            >
                              Order Via Whatsapp{" "}
                              <i class="fa-brands fa-whatsapp"></i>
                            </ReactWhatsapp>
                          </>
                        )}
                      </div>
                    </Modal.Footer>
                  </Modal>
                </button>
              </div>
            </button>
          </div>
        </Container>
      </Navbar>
    </>
  );
};

export default NavBar;