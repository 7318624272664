import React, { useEffect } from "react";
import { useState } from "react";
import Slider from "react-slick";

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none", background: "green" }}
      onClick={onClick}
    />
  );
}

const ProductSlider = ({ id, image1 }) => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let slider1 = [];
  let slider2 = [];

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  }, [slider1, slider2]);

  return (
    <>
      <div>
        <Slider
          asNavFor={nav2}
          ref={(slider) => (slider1 = slider)}
          nextArrow={<SamplePrevArrow />}
          prevArrow={<SamplePrevArrow />}
        >
          <div>
            <img
              src={image1}
              alt=""
              style={{
                width: "100%",
                minWidth: " 99.5%",
                height: "300px",
                maxHeight: "299px",
                minHeight: " 298px",
                objectFit: "contain",
              }}
            />
          </div>
        </Slider>
        <Slider
          asNavFor={nav1}
          ref={(slider) => (slider2 = slider)}
          slidesToShow={3}
          swipeToSlide={true}
          focusOnSelect={true}
          nextArrow={<SamplePrevArrow />}
          prevArrow={<SamplePrevArrow />}
        >
          <div>
            <img
              src={image1}
              alt=""
              style={{
                width: "100%",
                height: "150px",
                maxHeight: "149px",
                minHeight: " 148px",
                objectFit: "contain",
              }}
            />
          </div>
        </Slider>
      </div>
    </>
  );
};

export default ProductSlider;
