import React, { useEffect } from "react";
import Navbar from "../Components/NavBar";
import Footer from "../Components/Footer";
import imgtest from "../Assets/images/contact.03d0181f.gif";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Components/Loader";
import { aboutPageAction } from "../redux/aboutus";

const AboutUs = ({ history, match }) => {
  const dispatch = useDispatch();

  const aboutData = useSelector((state) => state.aboutPage);
  const { loading, data, error } = aboutData;

  useEffect(() => {
    dispatch(aboutPageAction());
  }, [dispatch]);

  return (
    <>
      {loading && <Loader />}

      {data && (
        <>
          <Navbar history={history} match={match} />
          <div className="provada-store-about">
            <div className="registration-steps-band artboard">
              <h2 style={{ textAlign: "center" }}>About Us</h2>
            </div>

            <section className="about-first-section">
              <div className="container">
                <h3>{data.left_paragragh_en}</h3>
                <div>
                  <p>{data.right_paragragh_en}</p>
                  <img src={data.image} alt="" />
                </div>
              </div>
            </section>

            <section className="about-video">
              <div className="container">
                <div>
                  <img src={imgtest} alt="" />
                </div>
              </div>
            </section>

            <section className="vision-mission-history-about">
              <div className="container">
                <div>
                  <i class="fa-solid fa-eye"></i>
                  <h3>Vision</h3>
                  <p>{data.vision_en}</p>
                </div>
                <div>
                  {/* <FontAwesomeIcon icon="fa-solid fa-bullseye-arrow" /> */}
                  <i class="fa-solid fa-bullseye"></i>
                  <h3>Mission</h3>
                  <p>{data.mission_en}</p>
                </div>
                <div>
                  <i class="fa-solid fa-clock-rotate-left"></i>
                  <h3>History</h3>
                  <p>{data.history_en}</p>
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default AboutUs;
