import React from 'react'

const SaleProduct = ({ data }) => {
    return (
        <>
            <div className="saleProduct-AR">
                <div className="container-fluid">
                    <div className='cale-num'>
                        <img src={data.right_image_banner} alt="Banner Image" />
                    </div>
                    <div className='cale-img'>
                        <h4>منتجاتنا الجديدة</h4>
                        <div className='single-product'>
                            <div className='img-container'>
                                <img src={data.product_1_image} alt={data.product_1_title} style={{ maxHeight: '155px', minHeight: '154px', maxWidth: '100px', objectFit: 'cover' }} />
                                <h4>{data.product_1_date}</h4>
                            </div>
                            <div>
                                <b>{data.product_1_title_ar}</b>
                                <p>{data.product_1_description_ar}</p>
                            </div>
                        </div>
                        <div className='single-product'>
                            <div className='img-container'>
                                <img src={data.product_2_image} alt={data.product_2_title} style={{ maxHeight: '155px', minHeight: '154px', maxWidth: '100px', objectFit: 'cover' }} />
                                <h4>{data.product_2_date}</h4>
                            </div>
                            <div>
                                <b>{data.product_2_title_ar}</b>
                                <p>{data.product_2_description_ar}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SaleProduct